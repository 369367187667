:root{
  --font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  margin: 0;
  padding: 0;
}
html{
  scroll-behavior: smooth;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
    width:100%;
    max-height: 100vh !important;
    min-height: 100vh !important;
}
video {
    width: 100%;
    max-height: 100vh !important;
    min-height: 100vh !important;
    object-fit: cover;  
    overflow: hidden;
}
.content{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.overlay {
    position: absolute;
    width: 100%;
    max-height: 100vh !important;
    min-height: 100vh !important;
    object-fit: cover;  
    overflow: hidden;
    background-color: rgba(0, 0, 0, .5);
}
.button {
    color: white !important;
    background-color: transparent !important;
    border-color: black !important;
    padding: 10px 40px;
}
.content a{
    background-color: transparent !important;
    border: 1px solid black;
    padding: 10px 40px;
    text-decoration: none;
}
.content a:hover {
background-color: black !important;
border-color: white;
cursor: pointer;
}
.main .content h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 50px;
}
.build .website {
    width: 22em;
}
.build .website {
    background-color: white;
    margin-left: 10px;
}
.build .website h1{
    color: black;
    font-weight: bold;
}
.build {
    display: flex;
}

@media screen and (max-width: 640px) {
    .content .build {
        display: block;
    }
    .content .build h1 {
        text-align: center;
    }
    
}
@media screen and (max-width: 500px) {
    .main .content h1 {
        text-align: center;
        font-size: 40px;
    }
   
}

.about-main {
    background-color: #f1f1f1;
    overflow: hidden;
}
.about {
    padding-bottom: 100px;
  
}
.about h1 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #000000;
}
.about p{
    font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 1.1rem;
}

@media screen and (max-width: 767px) {
    .about-main{
      /* width: 50% !important; */
      justify-content: center !important;
      align-items: center !important;
      padding: 5px 30px;
    }
  }
.process Image{
    width:100px;
}
.process h1 {
        font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: #000000;
}
.process p {
        font-family: "Open Sans", sans-serif;
      color: #000000;
      font-size: 1.1rem;
}
.img {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height:auto
}
.image{
    display: flex;
    justify-content: center;
    align-items: center;
}
.process .item .icon {
    float: left;
    color: #000000;
}
.process .item h4 {
    font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}
.process .item p {
    font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

@media screen and (max-width: 1050px){
  .img{
    /* width: 50% !important; */
    height: 50% !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px){
  .img{
    /* width: 50% !important; */
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 15px 30px;
    width: 500px !important;
  }
}
@media screen and (max-width: 767px) {
  .img{
    /* width: 50% !important; */
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 15px 30px;
  }
}


.box{
    width: 150px;
    height: 150px;
    background-color: white ;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px #888888;
}

.icon {
    flex-direction: column;
}
.services p {
    font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 1.1rem;
}
.services h1 {
    font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: #000000;
}
.services img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    object-position: center;
}
.services img:hover {
    cursor: pointer;
}
.services icon:hover{
    width: 80%;
    height: 80%;
}
.services h3{
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 18px;
    position: relative;
    border-bottom: 2px solid #5c9f24;
    padding-bottom: 15px;
    margin-top: 15px;
}
.services h3:hover {
    cursor: pointer;
    color: #5c9f24;
}
@media screen and (max-width: 767px) {
    .services p{
      /* width: 50% !important; */
      justify-content: center !important;
      align-items: center !important;
      padding: 5px 20px;
    }
  }

.navbar-links ul {
    list-style: none;
    display: flex;
    position: relative;
}
.navbar-links ul li{
    display: flex;
    text-decoration: none;
    color: rgba(255,255,255,0.8);
    display: inline-block;
    padding: 20px 0 !important;

}
.navbar-links ul li a{
    padding: 7px 7px 7px 7px;
    display: inline-block;

}
.navbar-links ul li a::after{
  content: "";
  border-bottom: 3px solid #fff;
  width: 100%;
  bottom: -6px;
  display: block;
  margin: 0 auto;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 0px;
}
.navbar-links a:hover:after, .navbar-links li:hover > a:after, .navbar-links .active:after, .nav-item .active:after {
    visibility: visible;
    width: 100%;
  }
.active {
    color: #fff !important;
}
.active:hover{
    color: #fff;
}
.navbar-links ul li a:hover{
    text-decoration: none;
    color: #fff !important;
    transition: all ease-in-out .3s;
}
.navbar-links_logo:hover{
    cursor: pointer;
}


.navbar-links a{
    color: unset;
    text-decoration: none;
    color: rgba(255,255,255,0.8);
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 .7rem;
    cursor: pointer;
}
.navbar-links_container p{
    text-decoration: none;
    color: white;
}

.navbar-menu_container-links a{
    color: unset;
    text-decoration: none;
    color: white;
}
.navbar-menu_container-links a:hover{
    color: unset;
    text-decoration: none;
    color: #5c9f24;
}
.navbar-menu_container-links p:hover{
    color: unset;
    text-decoration: none;
    color: #5c9f24;
}
.navbar-menu_container-links p{
    text-decoration: none;
    color: white;
}

.navbar{
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, .9);
    position: fixed;
    width: 100%;
    height: 100px;
    padding: 2rem 6rem;
    z-index: 9999;
}

.navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -20px;
}
.navbar-links_logo {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    margin-top: -20px;
}

.navbar-links_logo img {
    display: flex;
    max-width: 200px;
    margin-top: -15px;
    max-height: 150px;
    justify-content: flex-start;
    align-items: center;
}


.navbar-links_container {
    display: flex;
}
/* .nav-item .active {
    padding-bottom: 0px;
    border-bottom: 3px solid white;
} */

.navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container p {
    color:white;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}
.navbar-links .btn {
    padding: 10px 25px 8px 25px;
    margin-left: 12px;
    margin-top: -7px;
    /* border-radius: 4px; */
    color: #fff;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    border: 1px solid #ffffff;
}
.navbar-links .btn:hover{
    color: black;
    transition: all ease-in-out .3s;
}
.navbar-links .btn .active{
    background-color: white;
    color: black;
}
.navbar-menu {
    margin-left: 1rem;

    display:none;
    position: relative;
    margin-top: -30px;
}

.navbar-menu svg{
    cursor:pointer;
}

.navbar-menu_container {
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: rgb(0, 0, 0);
    padding:2rem;
    position:absolute;
    top:40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.navbar-menu_container p {
    margin: 1rem 0;
}

.navbar-menu-container-links-sign{
    display: none;
}

@media screen and (max-width: 1100px){
    .navbar-links{
        display: none;
    }
    
    .navbar-menu {
        display: flex;
        position: relative;
        z-index: 9999;

    }
}
@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 2rem;
    }

    .navbar-sign {
        display: none;
    }

    .navbar-menu_container {
        top: 20px;
    }

    .navbar-menu_container-links-sign {
        display: block;
        position: absolute;
        z-index: 9999;
    }
}

body {
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 1.1rem;
  max-width: 100%;
  overflow-x: hidden;

}
h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}

.header {
  position: relative;
  background-color: black;
  transition: all 0.5s;
  z-index: 1;
  height: 80px;
}
a{
  color: unset;
  text-decoration: none;
}
.scale-up-center {
-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/* ----------------------------------------------
* Generated by Animista on 2022-5-16 17:48:2
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation scale-up-center
* ----------------------------------------
*/
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px){
    .section__padding {
        padding: 4rem 6rem;
    }
    
    .section__margin{
        margin: 6rem;
    }
}
@media screen and (max-width: 550px){
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin{
        margin: 4rem 2rem;
    }
}
.App{
  max-width: 100%;
  overflow: hidden;
}
.center {
  min-height: 100vh;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, .5);
}
.whyus p{
    font-family: "Open Sans", sans-serif;
    color: #000000;
    font-size: 1.1rem;
}
.whyus h1 {
    font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: #000000;
}
.whyus .box:hover{
    background: #000000;
    box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
    color: #fff;
    cursor: pointer;
    transition: all ease-in-out .6s;
}
.whyus .box {
    padding: 150px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 0.3s;
  }
  .whyus .box p {
    color: #aaaaaa;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    .whyus p{
      /* width: 50% !important; */
      justify-content: center !important;
      align-items: center !important;
      padding: 5px 20px;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1000px) {

    .whyus .box{
      padding: 400px 30px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      transition: all ease-in-out 0.3s;
  }
  }
.form Button{
    background-color: black !important;
    border-color: white;
    padding: 15px 50px;
}
.form Button:hover {
background-color: white !important;
border-color: black;
color: black !important;
}
.contact h1 {
    font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: #000000;
}
.clients img {
    background-size: cover;
    transition: all 0.3s;
    max-width: 100%;
    max-height: 100%;
    display: block;
    width: 100%;
    padding: 0 10px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
  
  .clients .client:hover img {
    cursor: pointer;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  .clients .client:hover {
    cursor: pointer;
  }
  .clients a, .clients a:hover {
  text-decoration: none;
  color: #101010;
  }
  .clients h4 {
    text-transform: uppercase;
  }
  .clients .clients-button a{
    background-color: #101010 !important;
    border-color: black !important;
    padding: 15px 50px;
    color: #fff;
  }
  .clients .clients-button a:hover {
    background-color: rgb(223, 219, 219) !important;
    border-color: black !important;
    color: black !important;
    cursor: pointer;
    transition: all .3s;
  }
  
.footer {
    background: #1d1d1d;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
.footer a {
    text-decoration: none;
    color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
.footer p {
  color: #fff;
  line-height: 1.4;
}
.footer a:hover {
    color: #5c9f24 !important;
    cursor: pointer;
}

.footer ul {
  list-style: none;
  margin-left: -30px;
}
.footer .right-icon{
  color: #5c9f24;
  margin-right: 2px;
}
.copyright{
    text-align: center;
  padding-top: 30px;
  background-color: #111;
} 
.copyright p {
  color: #fff;
}
.copyright a {
  text-decoration: none;
  color:#8ed851;
}
.copyright a:hover {
  color: #5c9f24;
}
.footer h3 {
  font-size: 14px;
    font-weight: 400;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}
.footer .social-links a{
  font-size: 14px;
  margin-right: 30px;
}
.footer button {
  background-color:black;
  color: white;
  border-color: white;
}
.footer button:hover {
  background-color: white !important;
  border-color: white !important;
  color: black !important;
  }

@media screen and (max-width: 576px) {
  .footer{
    padding: 0px 20px;
  }
}
.skeleton {
    background: rgb(185, 183, 183);
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

.skeleton.image {
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.skeleton.title {
    width: 100%;
    height:50px;
    margin-top: 15px;
}

.skeleton.date {
    width: 50%;
    height: 30px;
    margin: 15px 0;
    display: flex;
    justify-content: center;
}

.skeleton.text {
    width: 100%;
    height: 15px;
    margin-top: 8px;
}
.skeleton-container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 60px auto;
}

.skeleton-hero {
    width: 40%;
    height: auto;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 50px;
    background: #f2f2f2;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.shimmer-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-animation: shimmering 1.5s infinite;
            animation: shimmering 1.5s infinite;
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
    -webkit-transform: skewX(10deg);
            transform: skewX(10deg);
}

@-webkit-keyframes shimmering {
    0% {
        -webkit-transform: translateX(-180%);
                transform: translateX(-180%);
    }

    50% {
        -webkit-transform: translateX(-80%);
                transform: translateX(-80%);
    }

    100% {
        -webkit-transform: translateX(180%);
                transform: translateX(180%);
    }
}

@keyframes shimmering {
    0% {
        -webkit-transform: translateX(-180%);
                transform: translateX(-180%);
    }

    50% {
        -webkit-transform: translateX(-80%);
                transform: translateX(-80%);
    }

    100% {
        -webkit-transform: translateX(180%);
                transform: translateX(180%);
    }
}

@media screen and (max-width: 700px) {
    .skeleton-hero {
        width: 80%;
      
    }
    
}
@media screen and (max-width: 1100px) {
 
    .skeleton-hero {
        width: 65%;
      
    }
}

/* Section About */
.skeleton-about {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;
    
}
.skeleton.title-about {
    width: 100%;
    height:50px;
    margin-top: 15px;
}

/* section Process  */
@media screen and (max-width: 1100px) {
 
    .skeleton.image {
        width: 100%;
        height: 350px;
        border-radius: 10px;
    }
}
@media screen and (max-width: 767px) {
 
    .skeleton.image {
        width: 80%;
        height: 350px;
        border-radius: 10px;
    }
}


/* Section Client */

.skeleton.image-clients {
    width: 100%;
    height: 400px;
    margin-top: 8px;
}
/* Section Services */
.skeleton.image-services {
    width: 100%;
    height: 150px;
    border-radius: 10px;
}
.skeleton.title-services {
    width: 100%;
    height:25px;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* Section Whyus */
.skeleton.title-whyus {
    width: 50%;
    height:40px;
    margin-top: 15px;
}
/* Section Contact */
.skeleton.form-contact {
    width: 100%;
    height:30px;
}
.clients img {
    background-size: cover;
    transition: all 0.3s;
    max-width: 100%;
    max-height: 100%;
    display: block;
    width: 100%;
    padding: 0 10px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .clients{
    padding: 50px 0px;
  }
  
  
  .clients .client:hover img {
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .clients .client:hover {
    cursor: pointer;
  }
  .clients a, .clients a:hover {
  text-decoration: none;
  color: #101010;
  }
  .clients h4 {
    text-transform: uppercase;
  }
  .clients .clients-button a{
    background-color: #101010 !important;
    border-color: black !important;
    padding: 15px 50px;
    color: #fff;
  }
  .clients .clients-button a:hover {
    background-color: rgb(223, 219, 219) !important;
    border-color: black !important;
    color: black !important;
    cursor: pointer;
    transition: all .3s;
  }
  
