body {
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 1.1rem;
  max-width: 100%;
  overflow-x: hidden;

}
h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}

.header {
  position: relative;
  background-color: black;
  transition: all 0.5s;
  z-index: 1;
  height: 80px;
}
a{
  color: unset;
  text-decoration: none;
}
.scale-up-center {
-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/* ----------------------------------------------
* Generated by Animista on 2022-5-16 17:48:2
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation scale-up-center
* ----------------------------------------
*/
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px){
    .section__padding {
        padding: 4rem 6rem;
    }
    
    .section__margin{
        margin: 6rem;
    }
}
@media screen and (max-width: 550px){
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin{
        margin: 4rem 2rem;
    }
}
.App{
  max-width: 100%;
  overflow: hidden;
}
.center {
  min-height: 100vh;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, .5);
}