.skeleton {
    background: rgb(185, 183, 183);
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

.skeleton.image {
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.skeleton.title {
    width: 100%;
    height:50px;
    margin-top: 15px;
}

.skeleton.date {
    width: 50%;
    height: 30px;
    margin: 15px 0;
    display: flex;
    justify-content: center;
}

.skeleton.text {
    width: 100%;
    height: 15px;
    margin-top: 8px;
}
.skeleton-container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 60px auto;
}

.skeleton-hero {
    width: 40%;
    height: auto;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 50px;
    background: #f2f2f2;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.shimmer-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: shimmering 1.5s infinite;
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
    transform: skewX(10deg);
}

@keyframes shimmering {
    0% {
        transform: translateX(-180%);
    }

    50% {
        transform: translateX(-80%);
    }

    100% {
        transform: translateX(180%);
    }
}

@media screen and (max-width: 700px) {
    .skeleton-hero {
        width: 80%;
      
    }
    
}
@media screen and (max-width: 1100px) {
 
    .skeleton-hero {
        width: 65%;
      
    }
}

/* Section About */
.skeleton-about {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;
    
}
.skeleton.title-about {
    width: 100%;
    height:50px;
    margin-top: 15px;
}

/* section Process  */
@media screen and (max-width: 1100px) {
 
    .skeleton.image {
        width: 100%;
        height: 350px;
        border-radius: 10px;
    }
}
@media screen and (max-width: 767px) {
 
    .skeleton.image {
        width: 80%;
        height: 350px;
        border-radius: 10px;
    }
}


/* Section Client */

.skeleton.image-clients {
    width: 100%;
    height: 400px;
    margin-top: 8px;
}
/* Section Services */
.skeleton.image-services {
    width: 100%;
    height: 150px;
    border-radius: 10px;
}
.skeleton.title-services {
    width: 100%;
    height:25px;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* Section Whyus */
.skeleton.title-whyus {
    width: 50%;
    height:40px;
    margin-top: 15px;
}
/* Section Contact */
.skeleton.form-contact {
    width: 100%;
    height:30px;
}