
.about-main {
    background-color: #f1f1f1;
    overflow: hidden;
}
.about {
    padding-bottom: 100px;
  
}
.about h1 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #000000;
}
.about p{
    font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 1.1rem;
}

@media screen and (max-width: 767px) {
    .about-main{
      /* width: 50% !important; */
      justify-content: center !important;
      align-items: center !important;
      padding: 5px 30px;
    }
  }