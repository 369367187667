
.navbar-links ul {
    list-style: none;
    display: flex;
    position: relative;
}
.navbar-links ul li{
    display: flex;
    text-decoration: none;
    color: rgba(255,255,255,0.8);
    display: inline-block;
    padding: 20px 0 !important;

}
.navbar-links ul li a{
    padding: 7px 7px 7px 7px;
    display: inline-block;

}
.navbar-links ul li a::after{
  content: "";
  border-bottom: 3px solid #fff;
  width: 100%;
  bottom: -6px;
  display: block;
  margin: 0 auto;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 0px;
}
.navbar-links a:hover:after, .navbar-links li:hover > a:after, .navbar-links .active:after, .nav-item .active:after {
    visibility: visible;
    width: 100%;
  }
.active {
    color: #fff !important;
}
.active:hover{
    color: #fff;
}
.navbar-links ul li a:hover{
    text-decoration: none;
    color: #fff !important;
    transition: all ease-in-out .3s;
}
.navbar-links_logo:hover{
    cursor: pointer;
}


.navbar-links a{
    color: unset;
    text-decoration: none;
    color: rgba(255,255,255,0.8);
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 .7rem;
    cursor: pointer;
}
.navbar-links_container p{
    text-decoration: none;
    color: white;
}

.navbar-menu_container-links a{
    color: unset;
    text-decoration: none;
    color: white;
}
.navbar-menu_container-links a:hover{
    color: unset;
    text-decoration: none;
    color: #5c9f24;
}
.navbar-menu_container-links p:hover{
    color: unset;
    text-decoration: none;
    color: #5c9f24;
}
.navbar-menu_container-links p{
    text-decoration: none;
    color: white;
}

.navbar{
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, .9);
    position: fixed;
    width: 100%;
    height: 100px;
    padding: 2rem 6rem;
    z-index: 9999;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -20px;
}
.navbar-links_logo {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    margin-top: -20px;
}

.navbar-links_logo img {
    display: flex;
    max-width: 200px;
    margin-top: -15px;
    max-height: 150px;
    justify-content: flex-start;
    align-items: center;
}


.navbar-links_container {
    display: flex;
}
/* .nav-item .active {
    padding-bottom: 0px;
    border-bottom: 3px solid white;
} */

.navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container p {
    color:white;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}
.navbar-links .btn {
    padding: 10px 25px 8px 25px;
    margin-left: 12px;
    margin-top: -7px;
    /* border-radius: 4px; */
    color: #fff;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    border: 1px solid #ffffff;
}
.navbar-links .btn:hover{
    color: black;
    transition: all ease-in-out .3s;
}
.navbar-links .btn .active{
    background-color: white;
    color: black;
}
.navbar-menu {
    margin-left: 1rem;

    display:none;
    position: relative;
    margin-top: -30px;
}

.navbar-menu svg{
    cursor:pointer;
}

.navbar-menu_container {
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: rgb(0, 0, 0);
    padding:2rem;
    position:absolute;
    top:40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.navbar-menu_container p {
    margin: 1rem 0;
}

.navbar-menu-container-links-sign{
    display: none;
}

@media screen and (max-width: 1100px){
    .navbar-links{
        display: none;
    }
    
    .navbar-menu {
        display: flex;
        position: relative;
        z-index: 9999;

    }
}
@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 2rem;
    }

    .navbar-sign {
        display: none;
    }

    .navbar-menu_container {
        top: 20px;
    }

    .navbar-menu_container-links-sign {
        display: block;
        position: absolute;
        z-index: 9999;
    }
}
