.footer {
    background: #1d1d1d;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
.footer a {
    text-decoration: none;
    color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
.footer p {
  color: #fff;
  line-height: 1.4;
}
.footer a:hover {
    color: #5c9f24 !important;
    cursor: pointer;
}

.footer ul {
  list-style: none;
  margin-left: -30px;
}
.footer .right-icon{
  color: #5c9f24;
  margin-right: 2px;
}
.copyright{
    text-align: center;
  padding-top: 30px;
  background-color: #111;
} 
.copyright p {
  color: #fff;
}
.copyright a {
  text-decoration: none;
  color:#8ed851;
}
.copyright a:hover {
  color: #5c9f24;
}
.footer h3 {
  font-size: 14px;
    font-weight: 400;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}
.footer .social-links a{
  font-size: 14px;
  margin-right: 30px;
}
.footer button {
  background-color:black;
  color: white;
  border-color: white;
}
.footer button:hover {
  background-color: white !important;
  border-color: white !important;
  color: black !important;
  }

@media screen and (max-width: 576px) {
  .footer{
    padding: 0px 20px;
  }
}