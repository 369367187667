.process Image{
    width:100px;
}
.process h1 {
        font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: #000000;
}
.process p {
        font-family: "Open Sans", sans-serif;
      color: #000000;
      font-size: 1.1rem;
}
.img {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height:auto
}
.image{
    display: flex;
    justify-content: center;
    align-items: center;
}
.process .item .icon {
    float: left;
    color: #000000;
}
.process .item h4 {
    font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}
.process .item p {
    font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

@media screen and (max-width: 1050px){
  .img{
    /* width: 50% !important; */
    height: 50% !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px){
  .img{
    /* width: 50% !important; */
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 15px 30px;
    width: 500px !important;
  }
}
@media screen and (max-width: 767px) {
  .img{
    /* width: 50% !important; */
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 15px 30px;
  }
}

