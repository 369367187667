.main {
    width:100%;
    max-height: 100vh !important;
    min-height: 100vh !important;
}
video {
    width: 100%;
    max-height: 100vh !important;
    min-height: 100vh !important;
    object-fit: cover;  
    overflow: hidden;
}
.content{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.overlay {
    position: absolute;
    width: 100%;
    max-height: 100vh !important;
    min-height: 100vh !important;
    object-fit: cover;  
    overflow: hidden;
    background-color: rgba(0, 0, 0, .5);
}
.button {
    color: white !important;
    background-color: transparent !important;
    border-color: black !important;
    padding: 10px 40px;
}
.content a{
    background-color: transparent !important;
    border: 1px solid black;
    padding: 10px 40px;
    text-decoration: none;
}
.content a:hover {
background-color: black !important;
border-color: white;
cursor: pointer;
}
.main .content h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 50px;
}
.build .website {
    width: 22em;
}
.build .website {
    background-color: white;
    margin-left: 10px;
}
.build .website h1{
    color: black;
    font-weight: bold;
}
.build {
    display: flex;
}

@media screen and (max-width: 640px) {
    .content .build {
        display: block;
    }
    .content .build h1 {
        text-align: center;
    }
    
}
@media screen and (max-width: 500px) {
    .main .content h1 {
        text-align: center;
        font-size: 40px;
    }
   
}