.box{
    width: 150px;
    height: 150px;
    background-color: white ;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px #888888;
}

.icon {
    flex-direction: column;
}
.services p {
    font-family: "Open Sans", sans-serif;
  color: #000000;
  font-size: 1.1rem;
}
.services h1 {
    font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: #000000;
}
.services img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    object-position: center;
}
.services img:hover {
    cursor: pointer;
}
.services icon:hover{
    width: 80%;
    height: 80%;
}
.services h3{
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 18px;
    position: relative;
    border-bottom: 2px solid #5c9f24;
    padding-bottom: 15px;
    margin-top: 15px;
}
.services h3:hover {
    cursor: pointer;
    color: #5c9f24;
}
@media screen and (max-width: 767px) {
    .services p{
      /* width: 50% !important; */
      justify-content: center !important;
      align-items: center !important;
      padding: 5px 20px;
    }
  }