.form Button{
    background-color: black !important;
    border-color: white;
    padding: 15px 50px;
}
.form Button:hover {
background-color: white !important;
border-color: black;
color: black !important;
}
.contact h1 {
    font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: #000000;
}