.clients img {
    background-size: cover;
    transition: all 0.3s;
    max-width: 100%;
    max-height: 100%;
    display: block;
    width: 100%;
    padding: 0 10px;
    transform: scale(1);
  }
  
  
  .clients .client:hover img {
    cursor: pointer;
    transform: scale(1.02);
  }
  .clients .client:hover {
    cursor: pointer;
  }
  .clients a, .clients a:hover {
  text-decoration: none;
  color: #101010;
  }
  .clients h4 {
    text-transform: uppercase;
  }
  .clients .clients-button a{
    background-color: #101010 !important;
    border-color: black !important;
    padding: 15px 50px;
    color: #fff;
  }
  .clients .clients-button a:hover {
    background-color: rgb(223, 219, 219) !important;
    border-color: black !important;
    color: black !important;
    cursor: pointer;
    transition: all .3s;
  }
  