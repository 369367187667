.whyus p{
    font-family: "Open Sans", sans-serif;
    color: #000000;
    font-size: 1.1rem;
}
.whyus h1 {
    font-family: "Roboto", sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: #000000;
}
.whyus .box:hover{
    background: #000000;
    box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
    color: #fff;
    cursor: pointer;
    transition: all ease-in-out .6s;
}
.whyus .box {
    padding: 150px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 0.3s;
  }
  .whyus .box p {
    color: #aaaaaa;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    .whyus p{
      /* width: 50% !important; */
      justify-content: center !important;
      align-items: center !important;
      padding: 5px 20px;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1000px) {

    .whyus .box{
      padding: 400px 30px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      transition: all ease-in-out 0.3s;
  }
  }